import {
  AppBar,
  Collapse,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  Slide,
  Toolbar,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Logo from "../images/PREP_Rectangle_Logo_PURPLE.png";
import HamburgerButton from "./HamburgerButton";
import InstagramButton from "./InstagramButton";

const HideOnScroll = ({ mobileOnly, children }) => {
  const trigger = useScrollTrigger();

  const slide = (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );

  if (mobileOnly) {
    return (
      <>
        <Hidden smDown> {children} </Hidden>
        <Hidden mdUp> {slide} </Hidden>
      </>
    );
  }

  return slide;
};

HideOnScroll.propTypes = {
  mobileOnly: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

const links = [
  {
    name: "HOME",
    path: "/",
  },
  {
    name: "SERVICES",
    path: "/#services",
  },
  {
    name: "THE TEAM",
    path: "/#theTeam",
  },
  {
    name: "BLOG",
    path: "/#blog",
  },
  {
    name: "CONTACT",
    path: "/#contact",
  },
  {
    name: "LOCATION",
    path: "/#location",
  },
  {
    name: "FOLLOW",
    path: "/#feed",
  },
];

const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();

  const drawer = (
    <div>
      <Divider />
      <List onClick={() => setMenuOpen(false)}>
        {links.map((link) => (
          <ListItem button key={link.name} divider>
            <AnchorLink
              to={link.path}
              gatsbyLinkProps={{
                style: {
                  color: "black",
                  textDecoration: "none",
                  minWidth: "100%",
                },
              }}
            >
              <Typography variant="subtitle1">{link.name}</Typography>
            </AnchorLink>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <HideOnScroll mobileOnly>
      <AppBar
        sx={{
          flexGrow: 1,
          backgroundColor: (theme) => theme.palette.transparent.white1,
        }}
        position="fixed"
      >
        <Toolbar disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginRight: theme.spacing(2),
            }}
          >
            <Hidden mdDown>
              <Grid item>
                <img
                  style={{
                    padding: "0px",
                  }}
                  src={Logo}
                  height="80px"
                  alt="logo"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    marginRight: "110px",
                  }}
                >
                  {links.map((link) => (
                    <Grid item key={link.name}>
                      <AnchorLink
                        to={link.path}
                        gatsbyLinkProps={{
                          style: {
                            padding: theme.spacing(1),
                            flexShrink: 0,
                            color: "black",
                            textDecoration: "none",
                            "&:last-of-type": {
                              marginRight: theme.spacing(2),
                            },
                          },
                        }}
                      >
                        <Typography variant="subtitle1">{link.name}</Typography>
                      </AnchorLink>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <InstagramButton />
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item>
                <InstagramButton />
              </Grid>
              <Grid item>
                <img
                  style={{
                    padding: "0px",
                  }}
                  src={Logo}
                  height="80px"
                  alt="logo"
                />
              </Grid>
              <Grid item>
                <HamburgerButton
                  open={menuOpen}
                  color="black"
                  onClick={() => setMenuOpen(!menuOpen)}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
        <Collapse in={menuOpen} timeout={500}>
          {drawer}
        </Collapse>
      </AppBar>
    </HideOnScroll>
  );
};

export default TopBar;
