import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Grid, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Copyright from "./Copyright";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address
            email
            phoneNumber
          }
        }
      }
    }
  `);

  const getAddress = () => data.site.siteMetadata.contact.address;
  const getPhoneNumber = () => data.site.siteMetadata.contact.phoneNumber;
  const getEmail = () => data.site.siteMetadata.contact.email;

  const wrapIconStyle = {
    alignItems: "center",
    display: "flex",
  };
  const iconStyle = {
    marginRight: (theme) => theme.spacing(1),
  };
  const linkStyle = {
    color: "inherit",
  };

  return (
    <Box style={{ padding: "20px" }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="body2" color="textSecondary" sx={wrapIconStyle}>
            <LocationOnIcon sx={iconStyle} /> {getAddress()}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" sx={wrapIconStyle}>
            <EmailIcon sx={iconStyle} />
            <a
              href={`mailto:${getEmail()}`}
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              {getEmail()}
            </a>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" sx={wrapIconStyle}>
            <LocalPhoneIcon sx={iconStyle} />
            <a
              href={`tel:${getPhoneNumber()}`}
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              {getPhoneNumber()}
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Copyright />
    </Box>
  );
};

export default Footer;
