import { Container, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const PageSection = ({ title, id, bgColor, labelColor, children }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        backgroundColor: bgColor,
      }}
    >
      <Container maxWidth="md">
        {title.split("\\n").map((line) => (
          <Typography
            key={line}
            variant="h3"
            style={{ color: labelColor, marginBottom: theme.spacing(2) }}
            align="center"
            id={id}
            gutterBottom
            display="block"
          >
            {line}
          </Typography>
        ))}
        <Typography
          variant="h3"
          style={{ color: labelColor, marginBottom: theme.spacing(2) }}
          align="center"
          gutterBottom
        >
          {" "}
        </Typography>
        {children}
      </Container>
    </div>
  );
};

PageSection.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  labelColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default PageSection;
