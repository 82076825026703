import { Link, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import tsAndCs from "../docs/Terms_and_Conditions.pdf";

const Copyright = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          homepage
        }
      }
    }
  `);

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={data.site.siteMetadata.homepage}>
        PREP Physio
      </Link>{" "}
      {new Date().getFullYear()}. Read our{" "}
      <Link color="inherit" href={tsAndCs} target="_blank">
        Terms and Conditions
      </Link>
      .
    </Typography>
  );
};

export default Copyright;
