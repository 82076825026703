import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const InstagramButton = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagramUrl
        }
      }
    }
  `);

  return (
    <IconButton
      color="primary"
      aria-label="follow"
      sx={{
        cursor: "pointer",
      }}
      onClick={() => window.open(data.site.siteMetadata.instagramUrl, "_blank")}
    >
      <InstagramIcon color="action" />
    </IconButton>
  );
};

export default InstagramButton;
